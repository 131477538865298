/* M E S S A G E S */

.chat-body-scroll {
  margin-bottom: 0px;
  height: 79%;
  padding: 20px 0 10px 0;
  overflow: auto;
  display: flex;
  flex-direction: column-reverse;
  background-color: #dce0e8;
  /* background: rgb(202, 201, 217);
  background: linear-gradient(
    90deg,
    rgba(202, 201, 217, 1) 3%,
    rgba(130, 199, 128, 1) 14%,
    rgba(175, 156, 186, 1) 82%,
    rgba(123, 154, 161, 1) 96%
  ); */
}
.chat-body-scroll li {
  padding: 0.5rem;
  display: flex;
}
.chat-body-scroll .avatar {
  width: 40px;
  height: 40px;
  position: relative;
  display: block;
  z-index: 2;
  border-radius: 100%;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  -ms-border-radius: 100%;
  background-color: rgba(255, 255, 255, 0.9);
}
.chat-body-scroll .avatar img {
  width: 40px;
  height: 40px;
  border-radius: 100%;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  -ms-border-radius: 100%;
  background-color: rgba(255, 255, 255, 0.9);
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
}
.chat-body-scroll .day {
  position: relative;
  display: block;
  text-align: center;
  color: #c0c0c0;
  height: 20px;
  text-shadow: 7px 0px 0px #e5e5e5, 6px 0px 0px #e5e5e5, 5px 0px 0px #e5e5e5,
    4px 0px 0px #e5e5e5, 3px 0px 0px #e5e5e5, 2px 0px 0px #e5e5e5,
    1px 0px 0px #e5e5e5, 1px 0px 0px #e5e5e5, 0px 0px 0px #e5e5e5,
    -1px 0px 0px #e5e5e5, -2px 0px 0px #e5e5e5, -3px 0px 0px #e5e5e5,
    -4px 0px 0px #e5e5e5, -5px 0px 0px #e5e5e5, -6px 0px 0px #e5e5e5,
    -7px 0px 0px #e5e5e5;
  box-shadow: inset 20px 0px 0px #e5e5e5, inset -20px 0px 0px #e5e5e5,
    inset 0px -2px 0px #d7d7d7;
  line-height: 38px;
  margin-top: 5px;
  margin-bottom: 20px;
  cursor: default;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
}

.other .msg {
  order: 1;
  border-top-left-radius: 0px;
  box-shadow: -1px 2px 0px #d4d4d4;
  background-color: #fafafa;
}
.other:before {
  content: '';
  position: relative;
  top: 0px;
  right: 0px;
  left: 40px;
  width: 0px;
  height: 0px;
  border: 5px solid #fff;
  border-left-color: transparent;
  border-bottom-color: transparent;
}

.self {
  justify-content: flex-end;
  align-items: flex-end;
}
.self .msg {
  order: 1;
  border-bottom-right-radius: 0px;
  box-shadow: 1px 2px 0px #d4d4d4;
  background-color: #d4ebf2;
}
.self .avatar {
  order: 2;
}
.self .avatar:after {
  content: '';
  position: relative;
  display: inline-block;
  bottom: 19px;
  right: 0px;
  width: 0px;
  height: 0px;
  border: 5px solid #d4ebf2;
  border-right-color: transparent;
  border-top-color: transparent;
  box-shadow: 0px 2px 0px #d4d4d4;
}

.msg {
  background: white;
  min-width: 50px;
  max-width: 50%;
  padding: 10px;
  border-radius: 2px;
  box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.07);
}
.msg p {
  font-size: 1rem;
  margin: 0 0 0.2rem 0;
  color: #262626;
  font-weight: lighter;
}
.msg img {
  position: relative;
  display: block;
  width: 100%;
  border-radius: 5px;
  box-shadow: 0px 0px 3px #eee;
  transition: all 0.4s cubic-bezier(0.565, -0.26, 0.255, 1.41);
  cursor: default;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
}

.msg video {
  position: relative;
  display: block;
  width: 100%;
  border-radius: 5px;
  box-shadow: 0px 0px 3px #eee;
  transition: all 0.4s cubic-bezier(0.565, -0.26, 0.255, 1.41);
  cursor: default;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
}

/* @media screen and (max-width: 800px) {
  .msg img {
    width: 100%;
  }
  .msg video {
    width: 100%;
  }
}
@media screen and (max-width: 550px) {
  .msg img {
    width: 100%;
  }
  .msg video {
    width: 100%;
  }
} */

.msg time {
  font-size: 0.7rem;
  color: gray;
  margin-top: 3px;
  float: right;
  cursor: default;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
}
.msg time:before {
  content: '\f017';
  color: #ddd;
  font-family: FontAwesome;
  display: inline-block;
  margin-right: 4px;
}
@-webikt-keyframes pulse {
  from {
    opacity: 0;
  }
  to {
    opacity: 0.5;
  }
}
