.login {
  display: flex;
  width: 100%;
  height: 100vh;
  align-items: center;
  justify-content: center;
}

.login_image {
  height: 100%;
  width: 100%;
  position: absolute;
  z-index: -5;
}

.container_login {
  max-width: 420px;
  min-width: 350px;
  background-color: white;
  border-radius: 8px;
  box-shadow: 10px 10px 28px -9px rgba(0, 0, 0, 0.75);
  align-items: center;
  justify-content: center;
  padding: 50px;
  padding-bottom: 20px;
  padding-top: 0px;
  text-align: center;
}