.page-header_account .ant-page-header-heading .ant-page-header-heading-extra {
    flex: 1 !important;
}

.page-header_shop .ant-page-header-heading .ant-page-header-heading-extra {
    flex: 1 !important;
}
.ant-comment-content .ant-comment-actions {
    margin-top: 0px;
}
.ant-comment .ant-comment-inner {
    padding: 0px 0;
    margin-bottom: 0px;
}

/* fixed hàng tính tổng của bảng antd */
.ant-table-summary {
    position: sticky;
    bottom: 0;
    background-color: white;
}
.custom-class-notification .ant-notification-notice-message {
    color: white;
}

.ant-form-item-label > label {
    font-weight: 600;
    font-size: 15px;
}

.ant-table-cell {
    font-weight: 500;
}

.ant-picker-range {
    border-radius: 4px;
}

.ant-input-search {
    border-radius: 4px;
}
.ant-btn {
    font-weight: 600;
    border-radius: 5px;
}
.ant-switch.ant-switch-checked {
    background-color: '#00abba';
    border-color: '#00abba';
}
.ant-modal-title {
    font-size: 22px;
    font-weight: 600;
}
.ant-form-item-control-input-content {
    font-size: 18px;
    font-weight: 500;
}
.ant-input-search .ant-input-group .ant-input-affix-wrapper:not(:last-child) {
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
}
.ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border-radius: 5px;
}
.ant-input-search
    > .ant-input-group
    > .ant-input-group-addon:last-child
    .ant-input-search-button:not(.ant-btn-primary) {
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
}
.ant-input {
    border-radius: 5px;
}
.ant-input-affix-wrapper {
    border-radius: 5px;
}
.ant-popover-inner {
    padding: 10px;
}
.ant-input-number {
    border-radius: 5px;
}
.ant-input-group > .ant-input:first-child,
.ant-input-group-addon:first-child {
}
.ant-switch-checked {
    background-color: #00abba;
}
.ant-col-7 {
    /* flex: none; */
    max-width: none;
}
.ant-col-5 {
    flex: none;
}
.ant-col-4 {
    flex: none;
    max-width: none;
}
.ant-page-header-heading-left {
    overflow: visible;
}
.ant-picker-focused {
    border-radius: 5px;
}
