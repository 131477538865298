.wapper-content {
    background-color: white;
    margin: 5px 10px;
    padding: 15px;
    border-radius: 5px;
}

.title-info {
    color: #CC0000;
    margin-top: 15px;
    margin-bottom: 0;
}

.type-product {
    padding: 10px;
    margin-left: 10px;
    background-color: white;
    border: 1px solid #d3d3d3;
}

.type-image {
    max-width: 100px;
    max-height: 100px;
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.ant-table-tbody .ant-table-row .ant-table-cell span {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}

.ant-image-img {
    object-fit: contain;
}