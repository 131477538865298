.orderCard {
  font-size: 16px;
  border-width: 1px;
  border-radius: 5px;
  border-style: solid;
  padding-bottom: 10px;
}
.titleDash {
  font-size: 18px;
  text-align: justify;
  font-weight: 500;
}
.divider {
  background-color: red;
  height: 2px;
  width: 150px;
  margin-top: 10px;
  margin-bottom: 20px;
}
.descriptions-live {
  width: 100%;
  display: flex;
  justify-content: end;
}

.order-dash {
  width: 100%;
  margin-right: 1%;
  background-color: white;
  padding: 15px 20px;
  border-radius: 5px;
}

.list-live {
  width: 45%;
  background-color: white;
  padding-top: 15px;
  padding-left: 20px;
  padding-right: 5px;
  border-radius: 5px;
}
