.status-active {
    padding-left: 5px;
    font-weight: 600;
    color: #388ab1;
}

.status-inactive {
    padding-left: 5px;
    font-weight: 600;
    color: #8b8b8b;
}

.wapper-form{
    width: 400px;
    height: 250px;
    border: 1px solid rgb(206, 206, 206);
    padding: 10px;
    border-radius: 10px;
    position: absolute;
    transform: translate(-50%,-50%);
    z-index: 3;
    top: 50%;
    left: 50%;
    right: 50%;
    background-color: white;
}

.edit-children{
    color: green;
    font-size: 20px;
    padding-left: 10px;
    cursor: pointer;
}