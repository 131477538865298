@media (min-width: 1341px) {
  .input-search_shop {
    min-width: 400px;
  }

  .select-status_shop {
    min-width: 200px;
  }
}

@media (min-width: 1641px) {
  .input-search_shop {
    min-width: 600px;
  }

  .select-status_shop {
    min-width: 250px;
  }
}
