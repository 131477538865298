.header_container {
    background-color: white;
    border-radius: 8px;
}
.content_filter_container {
    background-color: white;
    margin-top: 10px;
    padding: 15px;
    padding-bottom: 0;
    border-radius: 8px;
}

.content_container {
    background-color: white;
    margin-top: 10px;
    padding: 15px;
    padding-bottom: 20px;
    border-radius: 8px;
    position: 'relative';
    overflow: auto;
    overflow-x: hidden;
}

.content_container_with_height {
    background-color: white;
    margin-top: 10px;
    padding: 15px;
    padding-bottom: 20px;
    border-radius: 8px;
    position: 'relative';
    min-height: 400px;
}
