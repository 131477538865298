.wapper-info-order{
    background-color: white;
    border-radius: 10px;
    margin: 5px 10px;
    height: 100%;
}

.line{
    border-bottom: 2px solid rgb(224, 224, 224);
    margin: 0 18px;
}

.color_status{
    font-weight: bold;
    color: orange;
}