body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.gx-popover-horizantal{left:0 !important;padding-top:0;margin-top:-9px}

.ant-popover-title {
  padding: 10px 20px !important;
  font-size: 16px;
  font-weight: bold !important;
}

.ant-popover-inner-content {
  padding: 10px 4px !important;
}

.ant-popover-inner {
  border-radius: 6px !important;
}