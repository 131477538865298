.contendNotification {
  flex: 1;
  white-space: nowrap;
  word-break: break-word;
  line-height: 26px;
}
.timeNotification {
  line-height: 45px;
  color: gray;
}
.iconNotification {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 8px;
}

.see-more:hover {
  color: #1890ff;
}

.item-notification:hover {
  background-color: rgb(238, 238, 238);
  border-radius: 4px;
  cursor: pointer;
}

.ant-menu-submenu-popup {
  position: fixed !important;
}

.gx-popover-horizantal{left:0 !important;padding-top:0;margin-top:-9px}
