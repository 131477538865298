.container {
  display: flex;
  flex-direction: column;
  background-color: white;
  font-size: 20px;
}
.header {
  width: 100%;
  background: #dddd;
  height: 70;
  display: flex;
  justify-content: space-between;
}
.header-item {
  margin: 20px;
  font-size: 25px;
  color: red;
  font-weight: 800;
}
.download {
  width: 100%;
}
.content {
  margin: auto;
  width: 80%;
  min-height: 100%;
}
.footer {
  width: 100%;
  background-color: #333333;
  color: white;
  font-size: 24px;
}
.content-body-menu {
  width: 20%;
}
.content-body {
  display: flex;
}
.content-body-item {
  width: 80%;
  margin-left: 10px;
}
.post-content {
  margin: 20px;
  font-size: 20px;
}
.footer-content {
  display: flex;
  justify-content: space-between;
}
.footer-wraper {
  margin: 35px 50px;
}
.icon-fb {
  font-size: 30px;
}
.icon-yt {
  margin-left: 20px;
  font-size: 30px;
}
.footer-media {
  min-width: 200;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-items: center;
  /* flex-direction: row; */
}
.media-icon {
  align-items: center;
}
.media-desc {
  margin-bottom: 20;
}
.footer-logo {
  color: red;
  font-weight: 600;
}
.footer-content {
  margin-top: 20px;
}
.Blogs-Search {
  width: 20vw;
  margin: auto;
  height: 10vh;
  margin-top: 20px;
  margin-bottom: 20px;
}
.content-banner {
  position: relative;
}
.content-banner-androi-download {
  position: absolute;
  top: 250px;
  left: 650px;
}
.content-banner-ios-download {
  position: absolute;
  top: 250px;
  left: 850px;
}
.download {
  display: none;
}

.footer-address {
  max-width: 40%;
}
.post-list-desc {
  white-space: nowrap;
  width: 500px;
  overflow: hidden;
  text-overflow: ellipsis;
}
@media only screen and (max-width: 500px) {
  .content-banner-ios-download .content-banner-androi-download {
    display: none;
  }
  .download {
    display: block;
    margin: 10px 40%;
  }
  .content,
  .content-body-menu,
  content-body-item {
    width: 100%;
  }
  .content-body {
    display: block;
  }
  .content-body-item {
    align-items: center;
    width: 100%;
  }
  .Blogs-Search {
    width: 80%;
  }
  .footer-content {
    display: block;
  }
  .footer-address-desc {
    width: 80vw;
  }
  .post-list {
    margin: 10px;
    min-height: 10vh;
  }
  .post-list-text {
    font-size: 40px;
    /* text-align: justify; */
    margin-left: 10px;
    color: black;
  }
  .footer-media,
  .footer-address,
  .footer-contact {
    width: 100% !important;
    margin: 20px 0;
    font-size: 15px;
    text-align: center;
  }
  .media-desc {
    margin-left: 0px;
  }
  .post-content {
    margin-right: 20px;
  }
  .post-list-comment {
    margin-right: 10px;
  }
  .content {
    width: 100%;
    overflow-x: hidden;
  }
}

@media only screen and (max-width: 800px) {
  .content-banner {
    position: none;
  }
  .content-banner-ios-download,
  .content-banner-androi-download {
    display: none;
  }
  .download {
    display: block;
    margin: 10px 40%;
  }
  .content {
    width: 100%;
    margin: none;
  }
  .footer-address {
    max-width: 30%;
  }
  .footer-media {
    align-items: center;
  }
  .post-list-comment {
    margin-right: 15px;
  }
}
